<template>
  <ErrorFrame>
    <div ref="el" class="flex flex-col gap-xs">
      <div>
        {{ t('registration.error.userExists.text') }}
      </div>

      <div
        v-if="!hideLogin"
        class="flex gap-2 cursor-pointer place-items-center text-minilu-base font-pero text-18"
        @click="click"
      >
        <span class="anim__underline-link minilu-anim-link">
          {{ t('registration.error.userExists.toLogin') }}
        </span>
        <FaIcon icon-class="fas fa-chevron-right" />
      </div>
    </div>
  </ErrorFrame>
</template>
<script setup lang="ts">
import { DialogIdent, useDialogStore } from '~/stores/useDialogStore';
import ErrorFrame from '~~/src/components/formFields/components/errorFrame/errorFrame.vue';
import FaIcon from '@/components/fa-icon.vue';

defineProps({
  hideLogin: {
    type: Boolean,
    default: false,
  },
});

const el = ref<HTMLElement | null>(null);
const { t } = useTrans();
const dialogStore = useDialogStore();
const isInDialog = useIsInDialog(el);
const router = useRouter();

function click() {
  if (isInDialog.value) {
    dialogStore.openDialog(DialogIdent.LOGIN);
  } else {
    router.push('/login');
  }
}
</script>
