<template>
  <div ref="container" class="h-full bg-white md:rounded-minilu-xl">
    <div
      v-if="!initialized"
      class="grid w-full grid-cols-3 border border-minilu-border-light border-solid py-md md:grid-cols-3 gap-x-sm gap-y-sm animate-pulse px-md md:px-[120px] mb-sm md:rounded-minilu-xl pt-[53px] pb-xl"
    >
      <LoaderForm :repetitions="1" />
    </div>
    <div
      v-else
      class="px-sm sm:px-md md:px-[120px] pt-[53px] pb-xl h-full"
      :class="{
        '!pt-0': dialogStore.headline && isInDialog,
        'checkout-styles': isInCheckout,
      }"
    >
      <Form
        id="registration-form"
        headline="registration.headline"
        :submit-label="
          isInCheckout ? 'registration.submitAlt' : 'registration.submit'
        "
        :is-loading="isLoading"
        :scroll-offset="isInDialog ? dialogheaderHeight : 0"
        @submit="onSubmit"
      >
        <template #errors>
          <div
            v-if="
              errors.length &&
              (errors.includes(ErrorIdent.USER_ALREADY_EXISTS) ||
                errors.includes(ErrorIdent.USER_ALREADY_EXISTS_SALES_CHANNEL))
            "
          >
            <UserExistsError
              :hide-login="!errors.includes(ErrorIdent.USER_ALREADY_EXISTS)"
            />
          </div>
          <div
            v-if="errors.length && errors.includes(ErrorIdent.VAT_ID_NOT_FOUND)"
          >
            <ErrorFrame>
              {{ t('registration.error.vatIdNotFound') }}
            </ErrorFrame>
          </div>
          <div
            v-if="
              errors.length &&
              errors.includes(ErrorIdent.VERIFICATION_UPLOAD_ERROR)
            "
          >
            <ErrorFrame>
              {{ t('registration.error.verificationFile') }}
            </ErrorFrame>
          </div>
          <div
            v-if="errors.length && errors.includes(ErrorIdent.UNKNOWN_ERROR)"
          >
            <UnknownError />
          </div>
        </template>
        <template #default>
          <Dropdown
            id="registrationCustomerType"
            v-model="selectedType"
            :value="customerTypeOptions[0].value"
            :options="customerTypeOptions"
            name="customerType"
            label="registration.customerTypeLabel"
          >
            <template #info>
              {{ t('registration.info.customerType', { LABEL: t(label) }) }}
            </template>
          </Dropdown>
          <InputFile
            v-if="hasVerificationFiles"
            id="registrationFile"
            label="registration.verficationFileLabel"
            name="files"
            :required="false"
          >
            <template #info
              ><div>
                {{ t('registration.verficationFile.info.text') }}
                <ul v-if="$languageCode === 'de'" class="list-disc pl-md">
                  <li>{{ t('registration.verficationFile.info.option1') }}</li>
                  <li>{{ t('registration.verficationFile.info.option2') }}</li>
                  <li>{{ t('registration.verficationFile.info.option3') }}</li>
                  <li>{{ t('registration.verficationFile.info.option4') }}</li>
                  <li>{{ t('registration.verficationFile.info.option5') }}</li>
                  <li>{{ t('registration.verficationFile.info.option6') }}</li>
                </ul>
              </div>
            </template>
          </InputFile>
          <NotificationBar
            v-if="useCartStore().hasDrugItem && canPossiblyBuyDrugs"
            :type="NotificationType.WARN"
            description="registration.DrugVerificationHint"
            classes="!px-sm !gap-sm"
          />
          <NotificationBar
            v-if="showWarning"
            :type="NotificationType.WARN"
            description="registration.privatePersonWarning"
            classes="!px-sm !gap-sm"
          />
          <BillingAddress
            :register-data="registerData"
            :selected-type="selectedType"
          />
          <LoginData />

          <div class="mb-md">
            <Trans keypath="registration.dataSecurity.text">
              <NuxtLink
                to="/datenschutz"
                class="font-bold text-minilu-base"
                target="_blank"
                >{{ t('registration.dataSecurity.linkText') }}</NuxtLink
              >
            </Trans>
          </div>
        </template>
      </Form>
      <GoogleRecaptchaNote v-if="usesCaptcha" class="mt-sm md:mt-md" />
      <div
        :class="{
          'h-md md:h-xl': isInDialog,
        }"
      />
    </div>
    <AddressValidationChoice
      :address-validation-data="validationResult"
      :open="needsManualCheck"
      :highlight-suggestions="true"
      @address-chosen="(val) => handleFillAddress(val)"
    />
  </div>
</template>
<script setup lang="ts">
import BillingAddress from '@/components/form/registration/components/billingAddress/minilu/billingAddress.vue';
import LoginData from '@/components/form/registration/components/loginData/minilu/loginData.vue';
import LoaderForm from '@/components/loader/formContent.vue';
import Trans from '~~/src/components/Trans.vue';
import UserExistsError from '@/components/form/registration/components/errors/userExists/minilu/userExists.vue';
import UnknownError from '@/components/formFields/errors/unknown/unknownError.vue';
import { useRegistrationForm } from '@/components/form/registration/composables/useRegistrationForm';
import Form from '~/components/formFields/form/form.vue';
import { ErrorIdent } from '~/@types/errorIdents';
import { useIsInDialog } from '~/composables/useIsInDialog';
import ErrorFrame from '~/components/formFields/components/errorFrame/errorFrame.vue';
import { useDialogStore } from '@/stores/useDialogStore';
import { useCartStore } from '@/stores/useCart';
import AddressValidationChoice from '@/components/formTools/address/validation/addressChoice.vue';
import GoogleRecaptchaNote from '@/components/formTools/recaptcha/recaptchaNote.vue';
import {
  NotificationBar,
  NotificationType,
  InputFile,
  Dropdown,
} from '@/complib';

defineProps({
  isInCheckout: {
    type: Boolean,
    default: false,
  },
});
const { t } = useTrans();

const container = ref<HTMLElement | null>(null);
const isInDialog = useIsInDialog(container);
const dialogheaderHeight = 130;

const {
  onSubmit,
  customerTypeOptions,
  isLoading,
  initialized,
  errors,
  showSuccessScreen,
  registerData,
  selectedType,
  hasVerificationFiles,
  showWarning,
  canPossiblyBuyDrugs,
  needsManualCheck,
  validationResult,
  handleFillAddress,
  usesCaptcha,
} = useRegistrationForm(isInDialog);
const dialogStore = useDialogStore();

function getSelectedGroup() {
  return registerData.value.groups.find(
    (group) => group.id === selectedType.value,
  );
}

const label = computed(() => {
  if (selectedType.value) {
    return getSelectedGroup()?.label;
  }
  return 'registration.billingAddress.name';
});

const emit = defineEmits<{
  (e: 'success'): void;
}>();

watch(showSuccessScreen, (val) => {
  if (val) emit('success');
});
</script>

<style scoped lang="pcss">
.checkout-styles:deep(> *) {
  [type='submit'] {
    @apply !bg-minilu-btn-cta;
  }
}
</style>
